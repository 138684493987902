<template>
	<sf-form ref="configForm" v-model="currentConfig" name="groupConfig">
		<sf-label :label="$t('functionKeys.config.forward.typeLabel')" />
		<sf-checkbox-input
			v-for="type in ALL_FORWARD_TYPES"
			:key="type"
			v-model="currentSelection"
			type="radio"
			name="forwardType"
			:disabled="isOptionDisabled(type)"
			:label="$t(`functionKeys.config.forward.types.${type.toLowerCase()}`)"
			:value="type">
		</sf-checkbox-input>

		<name-input
			ref="nameInput"
			autofocus
			:default-name="$t('functionKeys.config.forward.defaultName')"
			:name-extension="$t(`functionKeys.config.forward.types.${currentSelection?.toLowerCase()}`)">
		</name-input>
	</sf-form>

	<sf-confirm @confirm="confirm" @abort="emits('abort')" />
</template>

<script lang="ts" setup>
	import { computed, ref } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfForm } from '@starface/starface-ui'
	import { ALL_FORWARD_TYPES, ForwardType, FunctionKeyConfig } from '../types'
	import { useFunctionKeyStore } from '../function-key-store'
	import NameInput from './components/input-name.vue'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeysByType } = storeToRefs(functionKeyStore)

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{ (e: 'success', config: FunctionKeyConfig): void, (e: 'abort'): void }>()

	const configForm = ref<InstanceType<typeof SfForm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()

	const getInitialForwardType = () => {
		return ALL_FORWARD_TYPES.find(type => !functionKeysByType.value('FORWARD')
			.map(key => key.config?.forwardType)
			.includes(type))
	}

	const defaultConfig = {
		name: '',
		forwardType: getInitialForwardType()
	}

	const currentConfig = ref({ ...(functionKey.value(props.id)?.config ?? defaultConfig) })
	const currentSelection = ref(currentConfig.value.forwardType)

	const isOptionDisabled = computed(() => (option: ForwardType) => {
		const configs = [...functionKeysByType.value('FORWARD').filter(key => key.uuid !== props.id).map(key => key.config)]
		return !!configs.find(config => config?.forwardType === option)
	})

	const confirm = () => {
		if (configForm.value?.validate()) {
			if (!currentConfig.value.name) {
				currentConfig.value.name = nameInput.value.fallback
			}

			emits('success', currentConfig.value as FunctionKeyConfig)
		}
	}
</script>
