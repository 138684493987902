import { ref } from 'vue'
import { useI18n, useToasts } from '@starface/starface-ui'

export type ResponseError = {
	code?: string
	message?: string
	errorDetails?: ErrorDetail[]
	validationMessages?: UniqueError[]
}

export type OAuthErrorResponse = {
	code?: string
	error?: string
	error_description?: string;
}

type UniqueError = {
	uuid: string
	description: string
}

type Errors = {
	responseJSON?: ResponseError
	errorCodes?: UniqueError[]
}

type ErrorDetail = {
	errorField: string
	functionKeyErrorType: 'DUPLICATE' | 'CORRUPT'
	position?: number
}

const errors = ref<Array<string>>([])

const { t } = useI18n()

export const useErrorOutput = () => {
	const translatedError = (code: string, message?: string): string => {
		const errorKey = 'functionKeys.errorCodes.' + code
		const translated = t(errorKey, [message])

		if (translated && translated !== errorKey) {
			return translated
		}

		return t(code, code)
	}

	const setErrors = (errs: string[]): boolean => {
		errors.value.length = 0
		errors.value.push(...errs)

		return errors.value.length > 0
	}

	const showErrors = () => {
		let message

		if (errors.value.length === 1) {
			message = errors.value[0]
		} else {
			message = '<ul><li>' + errors.value.join('</li><li>') + '</li></ul>'
		}

		useToasts().showSimpleToast({
			message: message,
			icon: 'exclamation'
		})
	}

	const errorMessage = (erreurs: Errors[] | Errors | ResponseError[] | ResponseError | string) => {
		if (Array.isArray(erreurs)) {
			erreurs.forEach((e) => {
				errorMessage(e)
			})
		} else if ((erreurs as Errors).errorCodes) {
			errorMessage({
				responseJSON: {
					validationMessages: (erreurs as Errors).errorCodes
				}
			})
		} else if ((erreurs as Errors).responseJSON) {
			const responseJson = (erreurs as Errors).responseJSON

			if (responseJson?.validationMessages) {
				errorMessage(responseJson.validationMessages
					.map((error: UniqueError) => ({ responseJSON: { code: error.uuid, message: error.description } })))
			}
		} else {
			const errs: string[] = []
			const responseJson = erreurs as ResponseError

			if (responseJson && responseJson.message && responseJson.code) {
				errs.push(translatedError(responseJson.code, responseJson.message))
			} else {
				errs.push(t(erreurs as string))
			}

			if (setErrors(errs)) {
				showErrors()
			}
		}
	}

	return { errorMessage }
}
