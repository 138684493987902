import RestClient from './RestClient'

export type ContactsResponse = {
	metadata: ContactMetadata
	summaryBlockSchema: SummaryBlockSchema
	phoneNumbersBlockSchema: PhoneNumbersBlockSchema
	contacts: Contact[]
}

export type Contact = {
	id: string
	summaryValues: string[]
	phoneNumberValues: string[]
	additionalValues: Record<string, never> | AdditionalValuesWithInternalNumber
}

export type ContactMetadata = {
	page: number
	pagesize: number
	sort: string
	sortdirection: 'ASC' | 'DESC'
	totalPages: number
}

interface BlockSchema {
	name: string
	resourceKey: string
	attributes: SchemaAttribute[]
}

/**
 * Represents the INTERNAL_NUMBER attribute.
 */
export interface AdditionalValuesWithInternalNumber extends Record<string, string> {
	INTERNAL_NUMBER: string
}

/**
 * Empty, but explicit type for summary block schema.
 */
export type SummaryBlockSchema = BlockSchema & Record<string, string>

/**
 * Empty, but explicit type for phone number block schema.
 */
export type PhoneNumbersBlockSchema = BlockSchema & Record<string, string>

export type SchemaAttribute = {
	displayKey: string
	name: string
	value: string
	additionalValues: Record<string, string | boolean | number> | null
}

export type Tag = {
	id: string
	name: string
	alias: string
	owner: string
}

export type QueryParams = {
	searchTerms: string
	page: number
	pagesize?: number
	sort?: string
	sortdirection?: 'ASC' | 'DESC'
	tags?: string
}

const PAGE_SIZE = 40

export default class Contacts {
	rest
	url = '/rest/contacts/'

	constructor() {
		this.rest = new RestClient(this.url)
	}

	get get(): Promise<ContactsResponse> {
		return (this.rest.response as unknown as Promise<ContactsResponse>)
	}

	search(params: QueryParams)
		: Promise<ContactsResponse> {
		params.pagesize = params.pagesize || PAGE_SIZE
		this.rest.url = this.url + '?' + Object.entries(params).map(e => e.join('=')).join('&')
		return this.rest.response.then(response => response.json()) as unknown as Promise<ContactsResponse>
	}

	get tags(): Promise<Tag[]> {
		this.rest.url = this.url + 'tags'
		return this.rest.response.then(response => response.json()) as unknown as Promise<Tag[]>
	}
}
