/*
 *  Copyright (C) by STARFACE GmbH, Karlsruhe, Germany. All rights reserved.
 *
 *  NOTICE: THIS MATERIAL IS CONSIDERED A TRADE SECRET BY STARFACE GMBH. UNAUTHORIZED ACCESS,
 *  USE, REPRODUCTION OR DISTRIBUTION IS PROHIBITED.
 */

import { defineStore } from 'pinia'
import { computed, Ref, ref } from 'vue'
import Login from '@/services/Login'
import { ResponseError, useErrorOutput } from '@/app/error-output'
import { useRoute } from 'vue-router'
import Users, { User } from '@/services/Users'
import Permissions, { Permission } from '@/services/Permissions'
import OAuthLogin from '@/services/OAuthLogin'

export type AppState = 'INIT' | 'READY' | 'ERROR' | 'UNAUTHORIZED'

const PBX_USER = import.meta.env.VITE_AUTH_ID
const PBX_PASS = import.meta.env.VITE_AUTH_PW

export const useAppStore = defineStore('app-store', () => {
	const authToken = ref<string>('')
	const visited = ref<string>('')
	const appState = ref<AppState>('INIT')
	const user: Ref<User | null> = ref(null)
	const userPermissions = ref(new Array<Permission>())

	const route = useRoute()

	const deleteToken = (): void => {
		authToken.value = ''
	}

	const storeToken = (t: string): void => {
		authToken.value = t.trim()
	}

	const deleteVisit = (): void => {
		visited.value = ''
	}

	const setVisit = (set: boolean): void => {
		if (set) {
			visited.value = '' + Date.now()
		} else {
			deleteVisit()
		}
	}

	const setToken = (token?: string): void => {
		if (!token || !token.trim().length) {
			deleteVisit()
			deleteToken()
		} else {
			storeToken(token)
			setVisit(true)
		}
	}

	const getVisit = () => visited.value

	const login = async () => {
		const tokenFromQueryParams = route.query.token as string ?? ''

		if (tokenFromQueryParams) {
			removeTokenParameterFromUrl()
			if (tokenFromQueryParams.startsWith('ey') && tokenFromQueryParams.length > 30) {
				return loginOAuth(tokenFromQueryParams)
			} else {
				return loginLegacy(tokenFromQueryParams)
			}
		} else {
			return loginOAuth()
		}
	}

	const removeTokenParameterFromUrl = () => {
		const fullUrl = window.location.href
		// window.location.search is not working because of vue-router uses hash mode
		const urlParts = fullUrl.split('?')
		const params = new URLSearchParams(urlParts[1])
		params.delete('token')
		window.history.replaceState({}, document.title, urlParts[0] + '?' + params.toString())
	}

	const loginLegacy = async (token: string) => {
		const loginClient = new Login()

		if (token) {
			authToken.value = token
			loginClient.refresh()

		} else if (process.env.NODE_ENV === 'development' && PBX_USER && PBX_PASS) {
			try {
				await loginClient.login({ id: PBX_USER, password: PBX_PASS })
			} catch (e) {
				useErrorOutput().errorMessage(e as ResponseError | string)
				appState.value = 'UNAUTHORIZED'
			}
		} else {
			appState.value = 'UNAUTHORIZED'
		}
	}

	const loginOAuth = async (prefered_refresh_token?: string) => {
		const oAuthClient = new OAuthLogin()

		const refresh_token = prefered_refresh_token || oAuthClient.refreshToken
		if (refresh_token) {
			try {
				const refreshSucceeded = await oAuthClient.performRefresh(refresh_token)
				if (refreshSucceeded) {
					oAuthClient.registerAutoRefresh()
				} else {
					useErrorOutput().errorMessage('refresh failed')
					appState.value = 'UNAUTHORIZED'
				}
			} catch (e) {
				useErrorOutput().errorMessage(e as ResponseError | string)
				appState.value = 'UNAUTHORIZED'
			}

		} else if (process.env.NODE_ENV === 'development' && PBX_USER && PBX_PASS) {
			try {
				await oAuthClient.login({ id: PBX_USER, password: PBX_PASS })
				oAuthClient.registerAutoRefresh()
			} catch (e) {
				useErrorOutput().errorMessage(e as ResponseError | string)
				appState.value = 'UNAUTHORIZED'
			}

		} else {
			appState.value = 'UNAUTHORIZED'
		}
	}

	const logout = async () => {
		try {
			await new Login().logout()
		} catch (e) {
			console.error(e)
		}

		deleteToken()
	}

	const fetchMe = async () => {
		return await new Users().me.then((userData) => {
			if (userData) {
				user.value = userData
			}
		})
	}

	const myId = () => '' + user.value?.id

	const fetchPermissions = async () => {
		const userId = user.value?.id as number
		if (!isNaN(userId)) {
			await new Permissions().getByUser(userId).then(result => {
				userPermissions.value.push(...result)
			})
		}
	}

	const hasPermissions = computed(() => (permissions: Permission[]): boolean => {
		let cnt = 0
		permissions.forEach((p) => {
			if (userPermissions.value.findIndex(up => up.id === p.id) >= 0) {
				cnt++
			}
		})
		return cnt === permissions.length
	})

	return {
		appState,
		authToken,
		hasPermissions,
		setToken,
		setVisit,
		getVisit,
		login,
		logout,
		fetchMe,
		fetchPermissions,
		myId
	}
})
