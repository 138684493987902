<template>
	<sf-form ref="configForm" v-model="currentConfig" name="quickForm">
		<sf-text-input
			name="directCallTargetnumber"
			:label="t('functionKeys.config.quick.number')"
			:maxlength="KEY_MAX_LENGTH"
			:placeholder="t('functionKeys.config.quick.basicPlaceholder')"
			:rules="[{
				rule: 'mandatory',
				message: t('functionKeys.config.quick.empty')
			}, {
				rule: KEY_REGEX_CALL_NUMBER_CODE,
				message: t('functionKeys.config.quick.invalid')
			}]" />

		<name-input ref="nameInput" default-name="" />
	</sf-form>

	<sf-confirm ref="confirmElement" :confirmButtonText="t('functionKeys.buttons.add')"
		@confirm="confirm" @abort="emits('abort')" />
</template>

<script lang="ts" setup>
	import { ref } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfForm, SfTextInput, useI18n } from '@starface/starface-ui'
	import { FunctionKeyConfig } from '../types'
	import { useFunctionKeyStore } from '../function-key-store'
	import NameInput from './components/input-name.vue'
	import {
		KEY_MAX_LENGTH,
		KEY_REGEX_CALL_NUMBER_CODE
	} from '../constants'

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{
		(e: 'success', config: FunctionKeyConfig): void,
		(e: 'abort'): void
	}>()

	const { t } = useI18n()
	const functionKeyStore = useFunctionKeyStore()
	const { functionKey } = storeToRefs(functionKeyStore)

	const configForm = ref<InstanceType<typeof SfForm>>()
	const confirmElement = ref<InstanceType<typeof SfConfirm>>()
	const currentConfig = ref({
		...(functionKey.value(props.id)?.config ?? {
			name: '',
			directCallTargetnumber: ''
		})
	})

	const confirm = () => {
		if (configForm.value?.validate()) {
			emits('success', currentConfig?.value as FunctionKeyConfig)
		}
	}
</script>
