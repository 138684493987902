<template>
	<sf-form ref="configForm" v-model="currentConfig" name="separatorConfig">
		<name-input autofocus :default-name="$t('functionKeys.config.separator.defaultName')" />
	</sf-form>

	<sf-confirm @confirm="confirm" @abort="emits('abort')" />
</template>

<script setup lang="ts">
	import { ref } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfForm } from '@starface/starface-ui'
	import { useFunctionKeyStore } from '../function-key-store'
	import { FunctionKeyConfig } from '../types'
	import NameInput from './components/input-name.vue'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey } = storeToRefs(functionKeyStore)

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{ (e: 'success', config: FunctionKeyConfig): void, (e: 'abort'): void }>()

	const defaultConfig = { name: '' }
	const currentConfig = ref({ ...(functionKey.value(props.id)?.config ?? defaultConfig) })

	const configForm = ref<InstanceType<typeof SfForm>>()

	const confirm = () => {
		if (configForm.value?.validate()) {
			emits('success', currentConfig.value)
		}
	}
</script>
