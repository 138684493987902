<template>
	<sf-spacer :factor="0.5" />

	<div class="flex">
		<i class="ui big phone icon" />

		<div>
			<p class="ui text no-margin"> {{ $t('functionKeys.info.phoneOnlyHint.main') }} </p>
			<p class="ui text no-margin"> {{ $t('functionKeys.info.phoneOnlyHint.sub') }} </p>
		</div>
	</div>

	<sf-spacer :factor="1" />
</template>

<script setup lang="ts">
</script>

<style lang="less" scoped>
  .flex {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .no-margin {
    margin-bottom: unset !important;
  }
</style>
