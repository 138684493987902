<template>
	<sf-modal id="function-key-delete" ref="modal" closable>
		<sf-header :text="$t('functionKeys.deleteDialog.header')" />

		<p class="ui text">{{ $t('functionKeys.deleteDialog.text') }}</p>

		<sf-confirm :confirm-button-text="$t('functionKeys.deleteDialog.confirm')" @confirm="confirmDelete"
			@abort="closeModal" />
	</sf-modal>
</template>

<script setup lang="ts">
	import { computed, ref } from 'vue'
	import { SfModal } from '@starface/starface-ui'
	import { useFunctionKeyStore } from '../function-key-store'
	import { storeToRefs } from 'pinia'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey } = storeToRefs(functionKeyStore)

	const modal = ref<InstanceType<typeof SfModal>>()

	const currentId = ref('')

	const currentFunctionKey = computed(() => functionKey.value(currentId.value))

	const closeModal = () => {
		modal.value?.close()
	}

	const deleteFunctionKey = (id: string) => {
		currentId.value = id

		if (currentFunctionKey.value) {
			modal.value?.open()
		}
	}

	const confirmDelete = () => {
		functionKeyStore.deleteFunctionKey(currentId.value)

		closeModal()
	}

	defineExpose({ deleteFunctionKey })
</script>
