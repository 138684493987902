<template>
	<sf-label :label="t('functionKeys.config.forwardToTarget.title')" />
	<sf-list relaxed>
		<sf-list-item v-for="option in forwardOptions" :key="option.id">
			<sf-checkbox-input :modelValue="option.checked" @click="addRemove(option)" />
			<i class="user icon" aria-label="user" />
			<span>{{ option.name }}</span>
		</sf-list-item>
	</sf-list>

	<sf-label :label="$t('functionKeys.config.forwardToTarget.forwardTo')" />
	<div class="flex">
		<sf-checkbox-input
			v-model="currentConfig.forwardTargetType"
			type="radio"
			name="forwardTargetType"
			:label="$t('functionKeys.config.forwardToTarget.vmb')"
			:value="'VOICEMAIL'">
		</sf-checkbox-input>

		<sf-checkbox-input
			v-model="currentConfig.forwardTargetType"
			type="radio"
			name="forwardTargetType"
			:label="$t('functionKeys.config.quick.number')"
			:value="'PHONENUMBER'">
		</sf-checkbox-input>
	</div>
	<sf-spacer :factor="0.5" />

	<sf-form ref="configForm" v-model="currentConfig" name="forwardConfig">
		<sf-dropdown
			v-if="currentConfig.forwardTargetType === 'VOICEMAIL'"
			v-model="currentSelection"
			:label="$t('functionKeys.config.forwardToTarget.vmb')"
			:data="vmbOptions">
		</sf-dropdown>

		<search-list
			v-if="currentConfig.forwardTargetType === 'PHONENUMBER'"
			:search-term="currentSearch"
			@searching="setCallNumber"
			@selected="setTarget">

			<search-input
				ref="searchInput"
				v-model="currentSearch"
				:label="$t('functionKeys.config.quick.number')"
				:placeholder="t('functionKeys.config.forwardToTarget.placeholder')">
			</search-input>
		</search-list>

		<name-input
			ref="nameInput"
			:default-name="t('functionKeys.config.forwardToTarget.defaultName')"
			:name-extension="nameExtension">
		</name-input>
	</sf-form>

	<sf-confirm ref="confirmElement" @confirm="confirm" @abort="emits('abort')" />
</template>

<script lang="ts" setup>
	import { computed, ref, watch } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfForm, SfCheckboxInput, SfDropdown, useI18n } from '@starface/starface-ui'
	import { useFunctionKeyStore } from '../function-key-store'
	import { FunctionKeyConfig, VoicemailBoxOption, AccountSearchResult } from '../types'
	import { KEY_REGEX_CALL_CODE, KEY_REGEX_CALL_NUMBER } from '../constants'
	import NameInput from './components/input-name.vue'
	import SearchInput from './components/input-search.vue'
	import SearchList from './components/search-list.vue'

	type ForwardOption = {
		id: number
		checked: boolean
		name: string
	}

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeys, forwards, voicemailBoxes } = storeToRefs(functionKeyStore)
	const { t } = useI18n()
	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{ (e: 'success', config: FunctionKeyConfig): void, (e: 'abort'): void }>()

	const configForm = ref<InstanceType<typeof SfForm>>()
	const confirmElement = ref<InstanceType<typeof SfConfirm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()

	const currentConfig = ref({
		...(functionKey.value(props.id)?.config ?? {
			forwardTarget: '',
			forwardTargetType: 'PHONENUMBER',
			name: '',
			redirectNumberIds: []
		}) as FunctionKeyConfig
	})

	const forwardOptions = computed(() => {
		return forwards.value.filter(forward => forward.redirectTrigger.redirectTriggerType === 'ALWAYS'
			&& !(forward.groupNumber))
			.map(forward => {
				const id = parseInt(forward.id.replace(/\D/g, ''))
				const checked = currentConfig.value.redirectNumberIds
					&& currentConfig.value.redirectNumberIds?.findIndex(fid => fid === id) >= 0
				return {
					checked,
					icon: 'user',
					id,
					name: forward.phoneNumber as string
				}
			})
	})

	const vmbOptions = computed(() => {
		let options: { [key: string]: string } = {}

		voicemailBoxes.value?.filter((vmb: VoicemailBoxOption) => !(vmb.group))
			.forEach(vmb => options[vmb.id] = vmb.name)

		return options
	})

	const currentSearch = ref(currentConfig.value.forwardTargetType === 'PHONENUMBER' ? currentConfig.value.forwardTarget : '')
	const currentSelection = ref(Object.keys(vmbOptions.value).find(key => key === currentConfig.value.forwardTarget) || Object.keys(vmbOptions.value)[0])

	watch(() => currentConfig.value.forwardTargetType, (newValue) => {
		if (newValue === 'VOICEMAIL') {
			currentConfig.value.forwardTarget = currentSelection.value
		} else {
			currentConfig.value.forwardTarget = currentSearch.value
		}
	})

	watch(() => currentSelection.value, (newValue) => {
		if (currentConfig.value.forwardTargetType === 'VOICEMAIL') {
			currentConfig.value.forwardTarget = newValue
		}
	})

	const setCallNumber = (result: { searchTerm: string, searchResult: AccountSearchResult[] }) => {
		if (validateCallNumber(result.searchTerm)) {
			if (currentConfig.value.forwardTarget !== result.searchTerm) {
				currentConfig.value.forwardTarget = result.searchTerm
			}
		} else {
			currentConfig.value.forwardTarget = ''
		}
	}

	const setTarget = (target: AccountSearchResult) => {
		currentSearch.value = target.phoneNumber
		currentConfig.value.forwardTarget = target.phoneNumber
	}

	const nameExtension = computed(() => {
		const from = forwardOptions.value.filter(option => !!currentConfig.value.redirectNumberIds?.find(id => id === option.id)).map(option => option.name).join(', ')
		const to = currentConfig.value.forwardTargetType === 'VOICEMAIL'
			? vmbOptions.value[currentConfig.value?.forwardTarget as string]
			: currentConfig.value.forwardTarget

		return (from && to) ? `${from} → ${to}` : ''
	})

	const addRemove = (option: ForwardOption) => {
		if (option.checked) {
			remove(option)
			option.checked = false
		} else {
			currentConfig.value.redirectNumberIds?.push(option.id)
		}
		confirmElement.value?.clearMessage()
	}

	const remove = (option: ForwardOption) => {
		currentConfig.value.redirectNumberIds = currentConfig.value.redirectNumberIds?.filter(id => id !== option.id)
	}

	const confirm = () => {
		if (configForm.value?.validate()) {
			if (invalidConfig()) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.forwardToTarget.error'))
			} else if (!validateVmb()) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.forwardToTarget.warning'))
			} else if (!validateCallNumber()) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.quick.invalid'))
			} else if (configExists()) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.forwardToTarget.exists'))
			} else {
				if (!currentConfig.value.name) {
					currentConfig.value.name = nameInput.value.fallback
				}

				emits('success', currentConfig.value as FunctionKeyConfig)
			}
		}
	}

	const invalidConfig = () => {
		return !(currentConfig.value.redirectNumberIds)
			|| currentConfig.value.redirectNumberIds?.length === 0
			|| emptyCallNumber()
	}

	const configExists = (): boolean => {
		const current = currentConfig.value.redirectNumberIds?.sort((a, b) => a - b)
		return functionKeys.value.filter((fk) => {
			return fk.uuid !== props.id && fk.config?.redirectNumberIds && fk.config?.redirectNumberIds.length > 0
				&& fk.config?.forwardTargetType === currentConfig.value.forwardTargetType
				&& fk.config?.forwardTarget === currentConfig.value.forwardTarget
		}).map(fk => fk.config?.redirectNumberIds?.sort((a, b) => a - b))
			.findIndex((fk) => {
				return JSON.stringify(fk) === JSON.stringify(current)
			}) > -1
	}

	const emptyCallNumber = (callNumber = currentConfig.value?.forwardTarget as string) => {
		return currentConfig.value.forwardTargetType === 'PHONENUMBER'
			&& callNumber.length === 0
	}

	const validateCallNumber = (callNumber = currentConfig.value?.forwardTarget as string) => {
		return !emptyCallNumber(callNumber) && (KEY_REGEX_CALL_NUMBER.test(callNumber)
			|| KEY_REGEX_CALL_CODE.test(callNumber))
	}

	const validateVmb = () => {
		return currentConfig.value.forwardTargetType === 'PHONENUMBER'
			|| /^[1-9]\d{3,}$/.test(currentConfig.value?.forwardTarget as string)
	}
</script>

<style scoped lang="less">
  .flex {
    display: flex;
    gap: 1rem;

    & > * {
      margin-top: unset !important;
    }
  }
</style>
