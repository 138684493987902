<template>
	<sf-text-input
		clearable
		icon="search"
		:disabled="props.disabled"
		:label="props.label"
		:placeholder="props.placeholder"
		@focus="() => showResultToggle = true">
	</sf-text-input>
</template>

<script lang="ts" setup>
	import { SfTextInput } from '@starface/starface-ui'
	import { inject } from 'vue'

	const props = withDefaults(defineProps<{
		disabled?: boolean
		label?: string
		placeholder?: string
	}>(), {
		disabled: false,
		label: '',
		placeholder: ''
	})

	const showResultToggle = inject('showResultToggle')
</script>
