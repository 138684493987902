import * as translationsEn from './__i18n__/en.json'
import * as translationsFr from './__i18n__/fr.json'
import * as translationsDe from './__i18n__/de.json'

export const functionKeyTranslationsDe = {
	functionKeys: translationsDe
}

export const functionKeyTranslationsEn = {
	functionKeys: translationsEn
}
export const functionKeyTranslationsFr = {
	functionKeys: translationsFr
}
