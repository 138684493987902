<template>
	<sf-form ref="configForm" v-model="currentConfig" name="urlConfig">
		<sf-text-input
			autofocus
			name="genericURL"
			:label="t('functionKeys.config.url.label')"
			:rules="[{
				rule: 'mandatory',
				message: t('functionKeys.config.messages.mandatory', [t('functionKeys.config.url.label')])
			}, {
				rule: KEY_REGEX,
				message: t('functionKeys.config.messages.chars', [KEY_REGEX_CHARS])
			}]">
		</sf-text-input>

		<name-input
			ref="nameInput"
			:default-name="t('functionKeys.config.url.label')"
			:name-extension="currentConfig.genericURL">
		</name-input>
	</sf-form>

	<phone-only-hint />

	<sf-confirm @confirm="confirm" @abort="emits('abort')" />
</template>

<script setup lang="ts">
	import { ref } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfForm, SfTextInput, useI18n } from '@starface/starface-ui'
	import { useFunctionKeyStore } from '../function-key-store'
	import { FunctionKeyConfig } from '../types'
	import { KEY_REGEX, KEY_REGEX_CHARS } from '../constants'
	import NameInput from './components/input-name.vue'
	import PhoneOnlyHint from './components/phone-only-hint.vue'

	const { t } = useI18n()
	const functionKeyStore = useFunctionKeyStore()
	const { functionKey } = storeToRefs(functionKeyStore)

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{ (e: 'success', config: FunctionKeyConfig): void, (e: 'abort'): void }>()

	const defaultConfig = {
		name: '',
		genericURL: ''
	}

	const currentConfig = ref({ ...(functionKey.value(props.id)?.config ?? defaultConfig) })

	const configForm = ref<InstanceType<typeof SfForm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()

	const confirm = () => {
		if (configForm.value?.validate()) {
			if (!currentConfig.value.name) {
				currentConfig.value.name = nameInput.value.fallback
			}

			emits('success', currentConfig.value)
		}
	}
</script>
