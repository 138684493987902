<template>
	<search-list
		type="ACCOUNT"
		:search-term="userSearch"
		:configs="currentConfigs"
		@selected="add">

		<search-input
			ref="searchInput"
			v-model="userSearch"
			:placeholder="$t('functionKeys.config.searchPlaceholder')">
		</search-input>
	</search-list>

	<template v-if="currentConfigs.length">
		<sf-separator />
		<sf-spacer :factor="0.5" />

		<sf-segment basic scrollable>
			<template v-for="(config, index) in currentConfigs" :key="config.blfAccountId">
				<sf-form ref="formElements" v-model="currentConfigs[index]" :name="'form-' + index">
					<name-input
						ref="nameInputs"
						:witheld-label="index > 0"
						:default-name="getAccount(config.blfAccountId).displayInformation"
						:name-extension="getAccount(config.blfAccountId).primaryInternalPhoneNumber">

						<sf-button icon="trash" class="delete-button" @click="remove(config)" />
					</name-input>
				</sf-form>
			</template>

			<sf-spacer :factor="0" />
		</sf-segment>
	</template>

	<sf-confirm
		ref="confirmElement"
		:confirmButtonText="$t('functionKeys.buttons.add')"
		@confirm="confirm"
		@abort="emits('abort')">
	</sf-confirm>
</template>

<script lang="ts" setup>
	import { computed, ref } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfForm, SfConfirm, useI18n } from '@starface/starface-ui'
	import { AccountSearchResult, FunctionKeyConfig } from '../types'
	import { useFunctionKeyStore } from '../function-key-store'
	import NameInput from './components/input-name.vue'
	import SearchInput from './components/input-search.vue'
	import SearchList from './components/search-list.vue'

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{
		(e: 'success', config: FunctionKeyConfig[]): void,
		(e: 'abort'): void
	}>()

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeyAccounts } = storeToRefs(functionKeyStore)

	const formElements = ref<Array<InstanceType<typeof SfForm>>>()
	const nameInputs = ref<Array<InstanceType<typeof NameInput>>>()
	const confirmElement = ref<InstanceType<typeof SfConfirm>>()

	const currentFunctionKey = computed(() => functionKey.value(props.id))
	const currentConfigs = ref<Array<FunctionKeyConfig>>(currentFunctionKey.value?.config ? [currentFunctionKey.value.config] : [])

	const userSearch = ref('')

	const getAccount = computed(() => (blfAccountId: number) => functionKeyAccounts.value.find(account => account.accountId === blfAccountId))

	const clearError = () => confirmElement.value?.clearMessage()

	const add = (account: AccountSearchResult) => {
		if (account.id && !(currentConfigs.value.find(cfk => cfk.blfAccountId === account.id))) {
			currentConfigs.value.push({ blfAccountId: parseInt('' + account.id), name: '' })
			userSearch.value = ''
		}

		clearError()
	}

	const remove = (fk: FunctionKeyConfig) => {
		currentConfigs.value = currentConfigs.value.filter(cfk => cfk.blfAccountId !== fk.blfAccountId)
	}

	const invalidConfig = () => currentConfigs.value.find(cfk => !cfk.blfAccountId || cfk.blfAccountId <= 0)

	const validateConfigs = () => currentConfigs.value.length && !invalidConfig()

	const confirm = () => {
		clearError()

		const inputValidation = formElements.value?.reduce((acc, form) => {
			if (!acc) {
				return false
			}

			return form.validate()
		}, true)

		if (!currentConfigs.value.length) {
			confirmElement.value?.showErrorMessage(useI18n().t('functionKeys.config.blf.error'))
		} else if (inputValidation) {
			if (validateConfigs()) {
				const configs = [...currentConfigs.value]

				configs.forEach((config, index) => {
					if (!config.name) {
						config.name = nameInputs.value?.[index].fallback ?? ''
					}
				})

				emits('success', configs)
			} else {
				confirmElement.value?.showErrorMessage(useI18n().t('functionKeys.config.signalling.error'))
			}
		}
	}
</script>

<style scoped lang="less">
  .delete-button {
    margin-left: 0.5rem;
  }
</style>
