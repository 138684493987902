<template>
	<div
		v-drag="{ key: 'functionKey', action: isPositioned ? 'move' : 'copy', data: id, interceptAction: true }"
		:title="text"
		class="ui card function-key" :class="{ disabled, inverted: isPhoneOnly }">

		<div class="content" :class="{ 'with-actions': isPositioned }"
			:title="isNotPermitted ? $t('functionKeys.info.keyPermission') : undefined">
			<sf-header
				size="tiny"
				:text="text"
				:icon="isPhoneOnly ? 'phone' : ''"
				:class="['key-text', shortType, { blank: isPositioned && !config?.name, 'with-icon': isPhoneOnly }]">
			</sf-header>

			<sf-buttons v-if="isPositioned" class="actions show-on-hover">
				<sf-button class="action edit" small inverted icon="pen" :disabled="isNotPermitted" @click="onEdit" />
				<sf-button class="action delete" small inverted icon="trash" @click="onDelete" />
			</sf-buttons>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed } from 'vue'
	import { useFunctionKeyStore } from './function-key-store'
	import { storeToRefs } from 'pinia'
	import { getTemplateForType, longToShortType } from './types'
	import { useI18n } from '@starface/starface-ui'

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, positionOfFunctionKey } = storeToRefs(functionKeyStore)

	interface Props {
		id: string,
		disabled?: boolean
	}

	interface Emits {
		(e: 'edit', id: string): void,
		(e: 'delete', id: string): void
	}

	const props = defineProps<Props>()
	const emits = defineEmits<Emits>()

	const storeKey = computed(() => functionKey.value(props.id))
	const config = computed(() => storeKey.value?.config)
	const shortType = computed(() => longToShortType(storeKey.value?.type))

	const isPositioned = computed(() => positionOfFunctionKey.value(props.id) >= 0)
	const isNotPermitted = computed(() => storeKey.value?.state === 'ERROR')
	const isPhoneOnly = computed(() => getTemplateForType(storeKey.value?.type)?.library === 'PHONE_ONLY')

	const text = computed(() => {
		if (config.value?.name) {
			return config.value?.name
		}

		let prop = 'functionKeys.key.' + shortType.value
		if (isPositioned.value) {
			if (shortType.value === 'quick') {
				return ''
			}
			if (shortType.value === 'separator') {
				prop = 'functionKeys.config.separator.defaultName'
			}
		}
		return useI18n().t(prop)
	})

	const onEdit = () => {
		emits('edit', props.id)
	}

	const onDelete = () => {
		emits('delete', props.id)
	}
</script>

<style scoped lang="less">
  @import "constants.less";

  body.dragging-state .show-on-hover {
    visibility: hidden !important;
  }

  .function-key.ui.card {
    --header-width: calc(@functionKeySize - 1rem);

    min-width: @functionKeySize;
    max-width: @functionKeySize;
    min-height: @functionKeyHeight;
    max-height: @functionKeyHeight;
    border-radius: @functionKeyBorderRadius;
    margin: unset;

    .content {
      padding: 0 4px 0 0.5rem;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;

      & > * {
        margin: unset;
      }

      .key-text {
        /* stylelint-disable-next-line -- px unit is required for icon-width */
        --icon-width: 0px;

        position: absolute;

        &.with-icon {
          --icon-width: 30px;
        }

        &:deep(.content) {
          width: calc(var(--header-width) - var(--icon-width)) !important;
          max-width: calc(var(--header-width) - var(--icon-width)) !important;
          overflow: hidden;
          text-overflow: ellipsis !important;
        }
      }

      .actions {
        margin-left: auto;

        .action:not(:last-of-type) {
          margin-right: 2px;
        }
      }

      &.with-actions:hover {
        --header-width: 8rem;
      }

      &:not(:hover) .show-on-hover {
        visibility: hidden;
      }
    }
  }
</style>
