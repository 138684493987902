<template>
	<sf-form ref="configForm" v-model="currentConfig" name="calllistConfig">
		<sf-label :label="t(`functionKeys.config.calllist.defaultName`)" />
		<div class="flex">
			<sf-checkbox-input
				v-for="option in listOptions"
				:key="option.value"
				v-model="currentSelection"
				name="callListRequest"
				type="radio"
				:disabled="option.disabled"
				:label="option.label"
				:value="option.value">
			</sf-checkbox-input>

			<sf-spacer factor="0.5" />
		</div>

		<name-input
			ref="nameInput"
			:default-name="t(`functionKeys.config.calllist.defaultName`)"
			:nameExtension="t(`functionKeys.config.calllist.${currentConfig.callListRequest?.toLowerCase()}`)" />
	</sf-form>

	<phone-only-hint />

	<sf-confirm
		ref="confirmElement"
		:confirmButtonText="t('functionKeys.buttons.add')"
		@confirm="confirm"
		@abort="emits('abort')">
	</sf-confirm>
</template>

<script lang="ts" setup>
	import { computed, ref } from 'vue'
	import { storeToRefs } from 'pinia'
	import { difference } from 'lodash'
	import { SfConfirm, SfForm, useI18n } from '@starface/starface-ui'
	import { CallListRequest, FunctionKeyConfig } from '../types'
	import { useFunctionKeyStore } from '../function-key-store'
	import NameInput from './components/input-name.vue'
	import PhoneOnlyHint from './components/phone-only-hint.vue'

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{
		(e: 'success', config: FunctionKeyConfig): void,
		(e: 'abort'): void
	}>()

	const { t } = useI18n()

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey, functionKeysByType } = storeToRefs(functionKeyStore)

	const confirmElement = ref<InstanceType<typeof SfConfirm>>()
	const configForm = ref<InstanceType<typeof SfForm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()

	const callListOptions: CallListRequest[] = ['INCOMING', 'MISSED', 'OUTGOING']

	const availableOption = computed(() => {
		const diff = difference(callListOptions, functionKeysByType.value('PHONECALLLIST')
			.map(fk => fk.config?.callListRequest))
		return diff.length ? diff[0] : null
	})

	const currentConfig = ref({
		...(functionKey.value(props.id)?.config ?? {
			name: '',
			callListRequest: availableOption.value
		})
	})

	const currentSelection = ref(currentConfig.value.callListRequest)

	const listOptions = computed(() => {
		return callListOptions.map(option => {
			const disabled = configExists(option)
			return {
				disabled,
				label: t(`functionKeys.config.calllist.${option.toLowerCase()}`),
				value: option
			}
		})
	})

	const configExists = (callListRequest = currentConfig.value.callListRequest) => functionKeysByType.value('PHONECALLLIST')
		.filter(fk => fk.uuid !== props.id)
		.findIndex(fk => fk.config?.callListRequest === callListRequest) >= 0

	const confirm = () => {
		if (configForm.value?.validate()) {
			if (!currentConfig.value.name) {
				currentConfig.value.name = nameInput.value.fallback
			}
			if (configExists()) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.calllist.exists'))
			} else {
				emits('success', currentConfig.value as FunctionKeyConfig)
			}
		}
	}
</script>

<style scoped lang="less">
  .flex {
    display: flex;
    gap: 1rem;

    & > * {
      margin-top: unset !important;
    }
  }
</style>
