<template>
	<sf-modal ref="modal" :closable="false">
		<sf-header :text="$t('functionKeys.info.saved')" size="medium" icon="info" />

		<p class="ui text">{{ $t('functionKeys.info.transfer') }}</p>

		<sf-header :text="$t('functionKeys.phoneDialog.connected', phones.length)" size="medium" />

		<template v-for="p in phones" :key="p.name">
			<sf-checkbox-input v-if="phones.length > 1" v-model="p.active"
				:name="p.name" :label="p.name" />
			<sf-header v-else :text="p.name" icon="phone" size="small" />
		</template>

		<sf-confirm
			:abortButtonText="$t('functionKeys.buttons.abort')"
			:confirmButtonText="$t('functionKeys.buttons.transfer')"
			@abort="emits('resolve')"
			@confirm="confirm">
		</sf-confirm>
	</sf-modal>
</template>

<script lang="ts" setup>
	import { ref } from 'vue'
	import FunctionKeySets from '@/services/FunctionKeySets'
	import { SfModal, SfCheckboxInput } from '@starface/starface-ui'
	import { useErrorOutput, ResponseError } from '@/app/error-output'

	type PhoneOption = {
		name: string
		active: boolean
	}

	const emits = defineEmits<{ (e: 'resolve'): void, (e: 'reject'): void }>()

	const error = useErrorOutput()

	const modal = ref<InstanceType<typeof SfModal>>()

	const phones = ref<Array<PhoneOption>>([])

	const loadPhones = (ph: string[]) => {
		if (ph.length) {
			phones.value.length = 0
			phones.value.push(...ph.map(phone => {
				return {
					name: phone,
					active: true
				}
			}))
			modal.value?.open()
		}
	}

	const confirm = async () => {
		const errorMessages: ResponseError[] = []
		const phoneNames: string[] = []

		if (phones.value.length === 1) {
			phoneNames.push(phones.value[0].name)
		} else {
			phones.value.forEach(phone => {
				if (phone.active) {
					phoneNames.push(phone.name)
				}
			})
		}

		const functionKeySets = new FunctionKeySets()

		for (const phoneName of phoneNames) {
			try {
				const response = await functionKeySets.putPhone('0', phoneName)

				if (!response.ok) {
					await Promise.reject(await response.json())
				}
			} catch (error) {
				errorMessages.push({
					code: (error as unknown as ResponseError).code,
					message: phoneName
				})
			}
		}

		if (errorMessages.length > 0) {
			error.errorMessage(errorMessages)
			modal.value?.close()
			emits('reject')
		} else {
			emits('resolve')
		}
	}

	defineExpose({ loadPhones })
</script>
