<template>
	<search-list
		type="CONTACT"
		:search-term="contactSearch"
		:configs="[currentConfig]"
		@selected="add">

		<search-input
			ref="searchInput"
			v-model="contactSearch"
			:placeholder="$t('functionKeys.config.quick.placeholder')">
		</search-input>
	</search-list>

	<sf-form ref="configForm" v-model="currentConfig" name="quickConfig">
		<name-input
			ref="nameInput"
			autofocus
			:disabled="!contactSearch"
			:default-name="currentName || contactSearch">
		</name-input>
	</sf-form>

	<sf-confirm
		ref="confirmElement"
		:confirmButtonText="$t('functionKeys.buttons.add')"
		@confirm="confirm"
		@abort="emits('abort')">
	</sf-confirm>
</template>

<script lang="ts" setup>
	import { ref } from 'vue'
	import { storeToRefs } from 'pinia'
	import { SfConfirm, SfForm, useI18n } from '@starface/starface-ui'
	import { KEY_REGEX_CALL_CODE, KEY_REGEX_CALL_NUMBER } from '../constants'
	import { FunctionKeyConfig } from '../types'
	import { useFunctionKeyStore } from '../function-key-store'
	import NameInput from './components/input-name.vue'
	import SearchInput from './components/input-search.vue'
	import SearchList from './components/search-list.vue'

	type ContactOption = {
		name: string,
		phoneNumber: string
	}

	const props = defineProps<{ id: string }>()
	const emits = defineEmits<{
		(e: 'success', config: FunctionKeyConfig): void,
		(e: 'abort'): void
	}>()

	const { t } = useI18n()

	const functionKeyStore = useFunctionKeyStore()
	const { functionKey } = storeToRefs(functionKeyStore)

	const confirmElement = ref<InstanceType<typeof SfConfirm>>()
	const configForm = ref<InstanceType<typeof SfForm>>()
	const nameInput = ref<InstanceType<typeof NameInput>>()

	const currentConfig = ref({
		...(functionKey.value(props.id)?.config ?? {
			name: '',
			directCallTargetnumber: ''
		})
	})

	const currentName = ref('')
	const contactSearch = ref(currentConfig.value.directCallTargetnumber || '')

	const clearError = () => {
		confirmElement.value?.clearMessage()
	}

	const validateCallNumber = (callNumber: string) => {
		return KEY_REGEX_CALL_NUMBER.test(callNumber) || KEY_REGEX_CALL_CODE.test(callNumber)
	}

	const add = (option: ContactOption) => {
		if (option.phoneNumber && currentConfig.value?.directCallTargetnumber !== option.phoneNumber) {
			currentConfig.value.name = ''
			contactSearch.value = option.phoneNumber
			currentName.value = option.name
		}

		clearError()
	}

	const confirm = () => {
		if (configForm.value?.validate()) {
			currentConfig.value.directCallTargetnumber = contactSearch.value

			if (!currentConfig.value.directCallTargetnumber) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.quick.error'))
			} else if (!validateCallNumber(currentConfig.value.directCallTargetnumber)) {
				confirmElement.value?.showErrorMessage(t('functionKeys.config.quick.invalid'))
			} else {
				if (!currentConfig.value.name) {
					currentConfig.value.name = nameInput.value.fallback
				}

				emits('success', currentConfig?.value as FunctionKeyConfig)
			}
		}
	}
</script>
